import logo from './logo.svg';
import './style/App.css';
import Login from './components/Login';

function App() {
  return (
    <>
      <Login></Login>
    </>
  );
}

export default App;
