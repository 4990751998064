import './../style/shared.css'
import './../style/background.css'

function Background() {
    return (
        <>
            <div className="background">
                <div className="bg-highlights">
                    <div className="bg-highlight-item"></div>
                    <div className="bg-highlight-item"></div>
                    <div className="bg-highlight-item"></div>
                    <div className="bg-highlight-item"></div>
                    <div className="bg-highlight-item"></div>
                </div>
            </div>
        </>
    )
}

export default Background;