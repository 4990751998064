import Background from "./Background"; 
import './../style/shared.css'
import './../style/login.css'

function Login() {
    return (
        <>
            <h1 className="title">tickitly</h1>
            <Background/>
            <div className="login-container">
                <input className="login-email" type="text" placeholder="E-Mail"/>
                <input className="login-pw" type="text" placeholder="Password"/>
                <p className="login-forgot">Reset password</p>
                <br/>
                <label className="login-checkbox-container">
                    <input type="checkbox"/>
                    <span className="login-custom-checkbox"></span>
                    Remember me
                </label>

                <button className="login-button">Login</button>
                <p className="login-infotext">If you need any help please contact our team.<br/>info@tickitly.de</p>
            </div>
        </>
    )
}

export default Login;